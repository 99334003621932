import QrCodes from "./qrCodes";
import Sidebar from "../common/sidebar";

const Index = () => {
  return (
    <div className="w-full d-flex">
      <div className="mt-10 w-[280px] me-lg-7">
        <Sidebar />
      </div>
      <div className="customDashboardSize">
        <QrCodes />
      </div>
    </div>
  );
};

export default Index;
