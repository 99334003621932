import axios from "axios";
import httpMethods from "..";

const AdminAPI = {

  getSubscribers: async (params) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .get(`/subscribers`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getUserLinks: async (params) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .get(`/short-url`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  sendWarningMail: async (params) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .post(`/warnig-email`, params, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },


  updateUrlStatus: async (payload) => {
    const urlId = payload.data.id;
    let deleteParams = { ...payload };
    delete deleteParams.data.id;
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .patch(`/short-url/disable/${urlId}`, deleteParams, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  deleteUrl: async (payload) => {
    const { id } = payload;
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .delete(`/delete-short-link/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  updateUserStatus: async (payload) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .post(`/active`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getStats: async (payload) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .post(`/stats`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getCurrentWeekUsers: async () => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .get(`/admin-users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getSubscriptions: async (payload) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .post(`/subscriptions`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getRecentSubscriptions: async () => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .get(`/recent-subscriptions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getRevenue: async (payload) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .post(`/revenue`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getSales: async (payload) => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods
      .post(`/sales`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
};

export default AdminAPI;
