import httpMethods from "..";

const SharedApi = {
    isSlugValid: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/valid-slug`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    getReidrectUrl: async (payload) => {
        return httpMethods
            .post(`/redirect-url`, payload)
            .then((res) => res);
    },

    getAnalytics: async (token, payload) => {
        return httpMethods
            .post(`/analytics`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            });
    },
    getBioLinkAnalytics: async (token, payload) => {
        return httpMethods
            .post(`/shortlinks-analytics`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            });
    },
    fetchTitle: async (token, url) => {
        return httpMethods
            .get(`/title-fetcher?url=${encodeURIComponent(url)}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            });
    },
    fetchCities: async (token, payload) => {
        return httpMethods
            .post(`/city-analytics`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            });
    },

    disableCors: async (token, payload) => {
        return httpMethods
            .post(`/disable-cors`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            });
    },
}


export default SharedApi;