import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Node width
const nodeWidth = 120;

const nodeStyle = {
  padding: 10,
  border: '1px solid #ddd',
  borderRadius: 5,
  minWidth: nodeWidth,
  maxWidth: 'content',
  boxSizing: 'border-box',
  background: '#faf3ff',
  color: 'purple'
};

// Component for Source Node
const SourceNode = ({ data, id, isEditing, onEdit }) => {
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [label, setLabel] = useState(data.label);

  const handleClick = () => {
    setIsEditingLabel(true);
    onEdit(id); // Notify parent to set the current node being edited
  };

  const handleChange = (e) => {
    setLabel(e.target.value);
  };

  const handleBlur = () => {
    setIsEditingLabel(false);
    // Update the label in the parent component
    data.onUpdateLabel(id, label);
  };

  return (
    <div
      style={nodeStyle}
      onClick={handleClick}
    >
      {isEditingLabel ? (
        <input
          type="text"
          value={label}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          style={{
            width: '100%',
            border: 'none',
            background: 'transparent',
            textAlign: 'center',
          }}
        />
      ) : (
        label
      )}
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: 'purple', width: 10, height: 10, borderRadius: '50%' }}
      />
    </div>
  );
};

// Component for Target Node
const TargetNode = ({ data, id, isEditing, onEdit }) => {
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [label, setLabel] = useState(data.label);

  const handleClick = () => {
    setIsEditingLabel(true);
    onEdit(id); // Notify parent to set the current node being edited
  };

  const handleChange = (e) => {
    setLabel(e.target.value);
  };

  const handleBlur = () => {
    setIsEditingLabel(false);
    // Update the label in the parent component
    data.onUpdateLabel(id, label);
  };

  return (
    <div
      style={nodeStyle}
      onClick={handleClick}
    >
      {isEditingLabel ? (
        <input
          type="text"
          value={label}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          style={{
            width: '100%',
            border: 'none',
            background: 'transparent',
            textAlign: 'center',
          }}
        />
      ) : (
        label
      )}
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: 'purple', width: 10, height: 10, borderRadius: '50%' }}
      />
    </div>
  );
};

export { SourceNode, TargetNode };
