import ShortLinks from "./short-links";
import QrCodes from "./qr-codes";
import Login from "./login";
import Signup from "./signup";
import CustomLinks from "./custom-links";
import CreateQrCode from "./create-qr-code/index";
import EditQrCode from "./create-qr-code/[id]";
import LinkInBio from "../pages/link-in-bio";
import CreateLinkInBio from "./create-link-in-bio";
import EditLinkInBio from "./create-link-in-bio/[id]";
import ResetPassword from "./reset-password";
import ForgotPassword from "./forgotPassword";
import UserSettings from "./user-settings";
import Subscription from "./subscription";
import Dashboard from "./user-dashboard";
import AdminDashboard from "./admin-dashboard";
import Subscribers from "./subscribers";
import VerifyAccount from "./verify-account";
import Contacts from "./conatcts";
import UserLinks from "./links";

const routes = {
  noAuthRoutes: [
    {
      path: "/",
      component: Login,
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/signup",
      component: Signup,
    },
    {
      path: "/forgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
    },
    {
      path: "/verify",
      component: VerifyAccount,
    },
  ],
  adminRoutes: [
    {
      path: "/admin-dashboard",
      component: AdminDashboard,
    },
    {
      path: "/subscribers",
      component: Subscribers,
    },
    {
      path: "/user-links",
      component: UserLinks,
    },
  ],
  routes: [
    {
      path: "/short-links",
      component: ShortLinks,
    },
    {
      path: "/custom-links",
      component: CustomLinks,
    },
    {
      path: "/link-in-bio",
      component: LinkInBio,
    },
    {
      path: "/create-link-in-bio",
      component: CreateLinkInBio,
    },
    {
      path: "/create-link-in-bio/:id",
      component: EditLinkInBio,
    },
    {
      path: "/qr-codes",
      component: QrCodes,
    },
    {
      path: "/create-qr-code",
      component: CreateQrCode,
    },
    {
      path: "/create-qr-code/:id",
      component: EditQrCode,
    },
    {
      path: "/user-settings",
      component: UserSettings,
    },
    {
      path: "/subscription",
      component: Subscription,
    },
    {
      path: "/dashboard",
      component: Dashboard,
    },
    {
      path: "/contacts",
      component: Contacts,
    },
    {
      path: "/Campaigns",
      component: ShortLinks,
    },
  ],
  // Add more routes as needed
};
export default routes;
