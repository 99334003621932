import QrCodesAPI from "../services/http/qrCodes";
import convertToURLFriendly from "./convertToURLFriendly";


const options = {
    data: "https://kut.lt",
    config: {
        erf1: [],
        erf2: ["fh"],
        erf3: ["fv"],
        brf1: [],
        brf2: ["fh"],
        brf3: ["fv"],
        gradientType: "radial",
        gradientOnEyes: false,
        //Logo
        logo: "",
        // corner
        eye: "frame0",
        eyeBall: "ball0",
        // pattern
        body: "round",
        // Color
        bodyColor: "#000000",
        // Background color
        bgColor: "#FFFFFF",
    },
    size: 300,
    download: "imageUrl",
    file: "png",
};


const patternMapper = {
    classy: "round",
    rounded: "square",
    "extra-rounded": "circle-zebra",
    "classy-rounded": "circular",
    square: "mosaic",
    dots: "circle",
};

const cornerMapper = {
    square: {
        eye: "frame0",
        eyeBall: "ball0",
    },
    "extra-rounded": {
        eye: "frame13",
        eyeBall: "ball15",
    },
    none: {
        eye: "frame10",
        eyeBall: "ball15",
    },
    8: {
        eye: "frame12",
        eyeBall: "ball14",
    },
    4: {
        eye: "frame2",
        eyeBall: "ball2",
        erf1: ["fh"],
        brf1: ["fh"],
    },
    5: {
        eye: "frame1",
        eyeBall: "ball1",
        erf1: ["fh"],
        erf3: ["fh"],
        brf1: ["fh"],
        brf3: ["fh"],
    },
    dot: {
        eye: "frame1",
        eyeBall: "ball1",
        erf1: ["fv"],
        erf2: ["fv"],
        brf1: ["fv"],
        brf2: ["fv"],
    },
    9: {
        eye: "frame0",
        eyeBall: "ball18",
    },
};

const imageDownloadHandler = (values, format, isQrCategory = true) => {
    const {
        color,
        bgColor,
        pattern,
        qrLogoId,
        brandedDomain,
        slashTag,
        corner,
        isBgTransparent,
        title
    } = values;

    try {
        const auth_token = localStorage.getItem("auth_token");
        QrCodesAPI.downloadQrCode(auth_token, {
            payload: {
                ...options,
                config: {
                    ...options?.config,
                    bodyColor: color,
                    bgColor: isBgTransparent ? "transparent" : bgColor,
                    eye1Color: color,
                    eye2Color: color,
                    eye3Color: color,
                    eyeBall1Color: color,
                    eyeBall2Color: color,
                    eyeBall3Color: color,
                    body: patternMapper[pattern],
                    ...cornerMapper[corner],
                    logo: qrLogoId,
                },
                data: `https://${brandedDomain}/${convertToURLFriendly(slashTag)}${isQrCategory ? '?r=qr' : ''}` || "https://kut.lt/",
                file: format
            },
        })
            .then(({ data }) => {
                const arrayBuffer = Uint8Array.from(data?.data?.data).buffer;
                const blob = new Blob([arrayBuffer], {
                    type: `image/${format}`,
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${title}.${format}`);
                document.body.appendChild(link);
                link.click();

            })
            .catch((error) => {
                console.log("Error while downloading qr code: ", error);
            })
    } catch (e) {
        console.log("Error while download qr code: ", e);
    }

}


export default imageDownloadHandler;