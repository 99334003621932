import moment from "moment/moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { Spinner } from "reactstrap";
import AdminAPI from "../../services/http/admin";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";

const Urls = ({
  tableData,
  urlsHandler,
  subscriberSelectorHandler,
  setLoading,
}) => {
  const {
    createdAt,
    expirationDate,
    title,
    destinationUrl,
    clicks,
    isActive,
    isadminblocked,
    id,
    userId,
    brandedDomain,
    slashTag,
    user,
  } = tableData || {};

  const [isLoading, setIsLoading] = useState(false);
  const [urlId, setUrlId] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const createdBy = user?.email ?? "-";
  const email = user?.email;

  // Update URL status
  const handleDisableUrl = async () => {
    try {
      setIsLoading(true);
      setUrlId(id);
      const response = await AdminAPI.updateUrlStatus({
        data: {
          id,
          status: String(isadminblocked),
        },
      });
      if (!response?.data?.error) {
        urlsHandler((preState) => {
          return preState?.map((item) =>
            item?.id === id
              ? {
                  ...item,
                  isadminblocked: response?.data?.data?.shortLink
                    ?.isadminblocked,
                }
              : item
          );
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log("Error while changing user status: ", e);
      setIsLoading(false);
    }
  };

  // Delete URL
  const handleOpenAlert = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this URL!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteUrl();
      }
    });
  };

  // Send email
  const handleSendEmail = async () => {
    try {
      setLoading(true);
      const response = await AdminAPI.sendWarningMail({
        data: {
          email,
          slashTag,
          brandedDomain: brandedDomain + "/",
          name: createdBy,
        },
      });
      if (!response?.data?.error) {
        Swal.fire({
          title: "Sent!",
          text: "Mail has been sent.",
          icon: "success",
        });
        setLoading(false);
      }
    } catch (e) {
      console.log("Error while changing user status: ", e);
      setLoading(false);
    }
  };

  // Open warning email alert
  const handleOpenWarningEmailAlert = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to send warning email!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSendEmail();
      }
    });
  };

  // Delete URL
  const handleDeleteUrl = async () => {
    try {
      setLoading(true);
      const response = await AdminAPI.deleteUrl({
        id,
      });
      if (!response?.data?.error) {
        urlsHandler((pre) => {
          return pre.filter((value) => value.id !== response?.data?.data?.id);
        });
        Swal.fire({
          title: "Deleted!",
          text: "URL has been deleted.",
          icon: "success",
        });
        setLoading(false);
      }
    } catch (e) {
      console.log("Error while changing user status: ", e);
      setLoading(false);
    }
  };

  // Copy to clipboard
  const handleCopyEmail = () => {
    navigator.clipboard.writeText(createdBy).then(() => {
      alert("Email copied to clipboard");
    });
  };

  return (
    <>
      <tr className="link-row border border-right-0 border-top-0 border-left-0">
        <td className="ps-6 visually-hidden">
          <div className="form-check form-check-sm form-check-custom form-check-info form-check-solid rounded-1">
            <input
              className="form-check-input rounded-1"
              type="checkbox"
              onChange={() => subscriberSelectorHandler(id)}
            />
          </div>
        </td>
        <td>
          <div
            title={title}
            className="text-gray-800 fw-bold my-auto d-block"
          >
            {title?.length > 30
              ? title[0].toUpperCase() + title?.slice(1, 30) + "..."
              : title[0].toUpperCase() + title?.slice(1, 30)}
          </div>
        </td>
        <td>
          <div className="text-gray-800 fw-bold my-auto d-block">
            <Link
              to={`https://${brandedDomain}/${slashTag || ""}`}
              target="_blank"
            >
              {`https://${brandedDomain}/${slashTag || ""}`}
            </Link>
          </div>
        </td>
        <td className="ps-2 cursor-pointer">
          <Link title={destinationUrl} to={destinationUrl} target="_blank">
            {destinationUrl?.length > 30
              ? destinationUrl?.slice(0, 30) + "..."
              : destinationUrl}
          </Link>
        </td>
        <td className="text-start pe-0">
          {!isadminblocked ? "Active" : "In-active"}
        </td>
        <td>
          <div className="text-gray-800 fw-bold">{userId || "---"}</div>
        </td>
        <td className="text-start pe-0">
          <div className="text-gray-800 fw-bold">
            {moment(createdAt).format("DD/MM/YY")}
          </div>
        </td>
        <td className="text-start pe-0">
          <div className="text-gray-800 fw-bold my-auto d-block">
            {moment(expirationDate).format("DD/MM/YY")}
          </div>
        </td>
        <td>
          <div className="text-gray-800 fw-bold my-auto d-block">
            <div className="d-flex align-items-center position-relative">
              <span
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className="me-2"
              >
                {createdBy}
              </span>
             {createdBy?.length > 3 &&  <button
                className="btn btn-sm btn-light"
                onClick={handleCopyEmail}
                aria-label="Copy email"
              >
                <i className="fa fa-copy"></i>
              </button>}
              {showTooltip && (
                <div className="tooltip">
                  Copy Email
                </div>
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="text-green-800 fw-bold my-auto d-block">{clicks}</div>
        </td>
        <td className="ps-3 cursor-pointer">
          <Dropdown className="d-inline nospce">
            <Dropdown.Toggle
              id="dropdown-autoclose-false"
              className="btn btn-icon bg-light-info rounded-1"
              variant="bg-light"
            >
              <i className="fa-regular fa-ellipsis-vertical fs-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-3">
              <Dropdown.Item href="#">
                <div
                  className="menu-item p-1 p-hover-2 bg-hover-light-info text-info d-flex align-items-center drop-items"
                  onClick={handleDisableUrl}
                >
                  <i className="fa-solid fa-envelope me-2 ms-1 text-info"></i>
                  {!isadminblocked ? "Block" : "Unblock"}
                </div>
                <div
                  className="menu-item p-1 p-hover-2 bg-hover-light-info text-info d-flex align-items-center drop-items"
                  onClick={handleOpenWarningEmailAlert}
                >
                  <i className="fa-solid fa-envelope me-2 ms-1 text-info"></i>
                  Mail
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#">
                <div
                  className="menu-item p-1 p-hover-2 bg-hover-light-info text-danger d-flex align-items-center drop-items"
                  onClick={handleOpenAlert}
                >
                  <i className="fa-solid fa-trash me-2 ms-1 text-danger"></i>
                  Delete
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default Urls;
