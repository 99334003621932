import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormGroup } from "react-bootstrap";
import useCustomLinks from "../../../hooks/useCustomLinks";
import {
  Input,
  Label,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { generateRandomString } from "../../../utils/generateRandomString";
import ShortLinksAPI from "../../../services/http/shortLinks";
import { AuthContxt } from "../../../store/authContxt";
import { URL_REGEX } from "../../../utils/enum";
import { BLOCKED_KEYWORDS } from "../../../config/constants";

const DEFAULT_VALUES = {
  titleLabel: "",
  destinationUrl: "",
  slashTag: "",
  brandedDomain: "kut.lt",
  clicks: 0,
  isActive: true,
};

export const urlBuilder = (customLink) => {
  const { domain, subDomain, spare } = customLink;
  try {
    const url = new URL(domain);
    return spare === "No" ? `${subDomain}.${url.host}` : url.host;
  } catch (e) {
    console.log("Error while creating url : ", e);
    return domain;
  }
};

const LinkForm = ({ form, edittedLink = null, setEdittedLink = () => {} }) => {
  const { watch } = form;
  const { links } = watch();
  const { fetchQuota } = useContext(AuthContxt);
  const customLinks = useCustomLinks();
  const isEdit = edittedLink ? true : false;
  const [addLink, setAddLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const messageHandler = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const createLinkValidation = yup.object().shape({
    destinationUrl: yup
      .string()
      // .url("Enter a valid URL")
      .matches(URL_REGEX, "Enter a valid URL")
      .required("Destination url is required")
      .test('is-not-in-list', 'Restricted Content', value => {
        if (!value) return true; // Skip validation if value is not provided (handled by required)
        const valueLowerCase = value.toLowerCase();
        return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
      }),
    titleLabel: yup.string().required("Label is required")
    .test('is-not-in-list', 'Restricted Content', value => {
      if (!value) return true; // Skip validation if value is not provided (handled by required)
      const valueLowerCase = value.toLowerCase();
      return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
    }),
    slashTag: yup
      .string()
      .required("Custom slug is required")
      .max(50, "Custom slug must be at most 50 characters!")
      .test('is-not-in-list', 'Restricted Content', value => {
        if (!value) return true; // Skip validation if value is not provided (handled by required)
        const valueLowerCase = value.toLowerCase();
        return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
      }),
  });

  const profileForm = useForm({
    resolver: yupResolver(createLinkValidation),
    defaultValues: DEFAULT_VALUES,
  });

  const { control, handleSubmit, reset, formState, setValue, trigger } =
    profileForm;
  const { errors } = formState;

  const cancelHandler = () => {
    setAddLink(false);
    reset();
    isEdit && setEdittedLink(null);
    setLoading(false);
  };

  const saveHandler = async (data) => {
    setLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    if (isEdit) {
      try {
        const response = await ShortLinksAPI.updateShortLink(
          auth_token,
          edittedLink?.id,
          {
            destinationUrl: data?.destinationUrl,
            titleLabel: data?.titleLabel,
          }
        );
        const { destinationUrl, titleLabel } = response?.data?.result;
        form.setValue(
          "links",
          links.map((item) =>
            item.id === edittedLink?.id
              ? { ...edittedLink, ...data, destinationUrl, titleLabel }
              : item
          )
        );
        fetchQuota();
        cancelHandler();
      } catch (e) {
        console.log("Error while updating button", e);
        setLoading(false);
      }
    } else {
      try {
        const response = await ShortLinksAPI.addNewShortLink(auth_token, {
          ...data,
          titleLabel: data?.titleLabel,
          type: "linkInBio",
        });
        form.setValue("links", [response?.data?.data, ...links]);
        await fetchQuota();
        cancelHandler();
      } catch (e) {
        messageHandler(
          e?.response?.data?.data?.message ||
            e?.response?.data?.message ||
            "Something went wrong"
        );

        console.log("Error while creating button", e);
        setLoading(false);
      }
    }
  };

  const handleUrlChange = async (e) => {
    const url = e.target.value;
    setValue("destinationUrl", url);
    if (isEdit) return;
    setValue("slashTag", generateRandomString(url));
    trigger("slashTag");
  };

  useEffect(() => {
    if (isEdit && edittedLink !== null) {
      setAddLink(true);
      reset({
        destinationUrl: edittedLink?.destinationUrl,
        slashTag: edittedLink?.slashTag,
        brandedDomain: edittedLink?.brandedDomain,
        titleLabel: edittedLink?.titleLabel,
      });
    } else {
      reset({
        destinationUrl: "",
        slashTag: "",
        titleLabel: "",
        brandedDomain: "kut.lt",
        clicks: 0,
        isActive: true,
      });
    }
  }, [edittedLink, isEdit, reset]);

  useEffect(() => {
    setAddLink(links?.length === 0);
  }, [links]);

  return (
    <>
      <div>
        <button
          disabled={addLink}
          type="button"
          onClick={() => setAddLink(true)}
          className="btn border rounded-1 border-gray-250 bg-light text-info w-100 py-4 mx-auto fs-4 fw-bold h-lg-60px"
        >
          <i className="fa-solid fa-plus me-2"></i>
          <span> Add Link</span>
        </button>
      </div>

      {addLink ? (
        <div
          className="px-5 py-lg-8 py-5 border bg-light rounded-1 mt-lg-8 mt-6"
          style={{ borderColor: "#f3f0fb !important" }}
        >
          <FormGroup>
            <Label className="fw-bold fs-5 mb-2">Destination URL</Label>
            <Controller
              name="destinationUrl"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleUrlChange(e);
                    }}
                    placeholder="https://example.com/enter-long-url"
                    className="form-control rounded-1 h-50px"
                  />
                </>
              )}
            />
            {errors.destinationUrl ? (
              <div style={{ textAlign: "end" }}>
                <span className="error-text text-danger">
                  {errors.destinationUrl.message}
                </span>
              </div>
            ) : null}
          </FormGroup>

          <div className="d-lg-flex justify-content-between">
            <FormGroup className="mt-lg-10 mt-6 me-lg-3 w-lg-50">
              <Label className="fw-bold fs-5 mb-2">Button Label</Label>
              <Controller
                name="titleLabel"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      className="form-control rounded-1 h-50px"
                      placeholder="Home"
                    />
                  </>
                )}
              />
              {errors.titleLabel ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.titleLabel.message}
                  </span>
                </div>
              ) : null}
            </FormGroup>

            <div className="mt-lg-10 mt-6 w-lg-50 mobile-hide">
              <label className="fw-bold fs-5 mb-2">Short Link Domain</label>
              <div className="input-group gap-3 justify-content-between mb-5 h-50px">
                <div className="custom-dropdown rounded-1 form-control p-0">
                  <div className="input-group justify-content-between h-50px">
                    <Controller
                      name="brandedDomain"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div>
                            <UncontrolledDropdown direction="down">
                              <DropdownToggle
                                disabled={isEdit}
                                caret
                                color="transparent"
                                className="h-50px"
                              >
                                {field.value || "kut.lt"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {customLinks &&
                                  customLinks.length > 0 &&
                                  customLinks?.map((dom) => (
                                    <DropdownItem
                                      onClick={() =>
                                        !isEdit &&
                                        field.onChange(urlBuilder(dom))
                                      }
                                    >
                                      {urlBuilder(dom)}
                                    </DropdownItem>
                                  ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        );
                      }}
                    />
                    <Controller
                      name="slashTag"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div>
                            <Input
                              {...field}
                              disabled={isEdit}
                              style={{
                                width: "180px",
                                borderRadius: "0px 5px 5px 0px",
                              }}
                              className="h-50px border-top-0 border-right-0 border-bottom-0"
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              placeholder="Slash Tag"
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <span className="ms-1 position-absolute translate-middle-y top-50 end-0 me-2">
                    <i className="ki-duotone ki-down fs-5"></i>
                  </span>
                </div>
              </div>
              {errors.slashTag ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.slashTag.message}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="mt-lg-10 mt-6 w-lg-50 desktop-hide">
              <label className="fw-bold fs-5 mb-2">Short Link Domain</label>
              <div className="mb-5">
                <div className="custom-dropdown rounded-1 p-0">
                  <div className="">
                    <Controller
                      name="brandedDomain"
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <div className="bg-white border border-gray-300 rounded-1">
                            <UncontrolledDropdown direction="down">
                              <DropdownToggle
                                disabled={isEdit}
                                caret
                                color="transparent"
                                className="h-50px"
                              >
                                {field.value || "kut.lt"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {customLinks &&
                                  customLinks.length > 0 &&
                                  customLinks?.map((dom) => (
                                    <DropdownItem
                                      onClick={() =>
                                        !isEdit &&
                                        field.onChange(urlBuilder(dom))
                                      }
                                    >
                                      {urlBuilder(dom)}
                                    </DropdownItem>
                                  ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        );
                      }}
                    />
                    <Controller
                      name="slashTag"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className="mt-6">
                            <Input
                              {...field}
                              disabled={isEdit}
                              className="h-50px"
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              placeholder="Slash Tag"
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <span className="ms-1 position-absolute translate-middle-y top-50 end-0 me-2">
                    <i className="ki-duotone ki-down fs-5"></i>
                  </span>
                </div>
              </div>
              {errors.slashTag ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.slashTag.message}
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-end mb-3">
            {message ? (
              <span className="error-text text-danger">{message}</span>
            ) : null}
          </div>

          <div className="d-flex gap-5 mt-6 justify-content-between">
            <button
              type="button"
              onClick={cancelHandler}
              className="btn btn-light border border-gray-250 rounded-1"
            >
              Cancel
            </button>
            <button
              disabled={loading}
              type="button"
              onClick={handleSubmit(saveHandler)}
              className="btn btn-info h-50px rounded-1 w-250px"
            >
              {loading ? <Spinner /> : isEdit ? "Update" : "Save"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LinkForm;
