import { useContext, useEffect, useMemo, useState } from "react";
import DateRangeSelector from "../../common/analytics/dateRange";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import SharedApi from "../../../services/http/shared";
import { Spinner } from "reactstrap";
import Chart from "./chart";
import analyticsempty from "../../../assets/media/empty-state/analytics.svg";
import convertToURLFriendly from "../../../utils/convertToURLFriendly";
import { LinkInBioContxt } from "../linkInBioContxt";

const AnalyticsNotFound = () => {
  return (
    <div
      style={{ width: "100%" }}
      className="d-flex flex-column justify-content-center"
      id="no-analytics-message"
    >
      <img
        src={analyticsempty}
        className="w-lg-300px w-75 opacity-75 mx-auto mb-10"
        alt=""
      />
      <h2 className="mx-10 opacity-75 text-center">No analytics available !</h2>
      <p className="w-lg-75 mx-auto text-center">
        Share page on social media or other platforms to get insights
      </p>
    </div>
  );
};

const Analytics = () => {
  const {
    bioPageForm: { watch },
  } = useContext(LinkInBioContxt);
  const { links, pageDomain, pageLink } = watch();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date().setDate(new Date().getDate() - 30),
    endDate: new Date(),
    key: "selection",
  });

  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [analyticsNotFound, setAnalyticsNotFound] = useState(false);

  const [linkClicks, setLinkClicks] = useState([]);

  const fetchAnalytics = (
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    setLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    const shortlink = `https://${pageDomain}/${convertToURLFriendly(pageLink)}`;
    SharedApi.getAnalytics(auth_token, {
      shortlink,
      startDate,
      endDate,
    })
      .then(({ data }) => {
        const { country, city, date, ip, device } = data?.resultData;
        const isEmpty = (arr) => arr.length === 0;
        const areAnalyticsFetched = [country, city, date, ip, device].every(
          isEmpty
        );
        setAnalyticsNotFound(areAnalyticsFetched);

        setAnalytics(data?.resultData);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBioLinkAnalytics = async (
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    const shortlinks = links?.map(
      (link) =>
        `https://${link.brandedDomain}/${convertToURLFriendly(
          link.slashTag
        )}?r=lb`
    );
    const auth_token = localStorage.getItem("auth_token");
    SharedApi.getBioLinkAnalytics(auth_token, {
      shortlinks,
      startDate,
      endDate,
    })
      .then(({ data }) => {
        setLinkClicks(data?.resultData);
      })
      .catch((error) => {
        console.log("Error while fetching bio link analytics: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBioLinkAnalytics();
  }, []);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const {
    country = [],
    city = [],
    date = [],
    ip = [],
    device = [],
  } = analytics;

  const totalPageViews = useMemo(() => {
    const values = date?.map((item) => item?._count?._all);
    const totalPageViews = values?.reduce((total, val) => total + val, 0) || 0;
    return totalPageViews;
  }, [date]);

  const totalLinkClicks = useMemo(() => {
    return linkClicks?.reduce((total, link) => link?.counts + total, 0) || 0;
  }, [linkClicks]);

  const countries = useMemo(() => {
    return country.map((item) => {
      return {
        name: item?.country === "" ? "Others" : item?.country,
        clicks: item?._count?._all,
        percent: ((item?._count?._all / totalPageViews) * 100).toFixed(2),
      };
    });
  }, [country, totalPageViews]);

  const cities = useMemo(() => {
    return city.map((item) => {
      return {
        name: item?.city === "" ? "Others" : item?.city,
        clicks: item?._count?._all,
        percent: ((item?._count?._all / totalPageViews) * 100).toFixed(2),
      };
    });
  }, [city, totalPageViews]);

  const devices = useMemo(() => {
    const namesMapper = {
      smartphone: "Mobile",
    };

    const capitalizeFirstLetter = (item = "") => {
      return item?.charAt(0)?.toUpperCase() + item?.slice(1) || "";
    };
    return device.map((item) => {
      return {
        name:
          item?.device === ""
            ? "Others"
            : `${
                namesMapper[item?.device] || capitalizeFirstLetter(item?.device)
              }`,
        clicks: item?._count?._all,
        percent: ((item?._count?._all / totalPageViews) * 100).toFixed(2),
      };
    });
  }, [device, totalPageViews]);

  function generateShortlink(link) {
    return `https://${link.brandedDomain}/${convertToURLFriendly(
      link.slashTag
    )}?r=lb`;
  }

  const updatedLinks = useMemo(() => {
    let transformedLinks = links
    linkClicks?.forEach((item) => {
      const shortlink = item.shortlink;
      const counts = item.counts;

      transformedLinks?.forEach((link) => {
        const generatedShortlink = generateShortlink(link);
        if (generatedShortlink === shortlink) {
          link.lbClicks = counts;
        }
      });
    });
    return transformedLinks;
  }, [linkClicks]);

  return (
    <div
      className="tab-pane mb-20 pb-6 mb-lg-0"
      id="kt_tab_pane_9"
      role="tabpanel"
    >
      <div className="d-lg-flex justify-content-between align-items-end mb-3">
        <h3 className="fw-bolder fs-2 mb-0">Overview</h3>
        <div className="mt-5 mt-lg-0">
          <div
            onClick={() => setOpenDatePicker(!openDatePicker)}
            class="form-control form-control-solid w-lg-250px justify-content-between cursor-pointer"
            style={{
              position: "relative",
            }}
          >
            <span>
              {dayjs(selectedDateRange.startDate).format("DD/MM/YYYY")}
            </span>
            <span>{` - `}</span>
            <span>{dayjs(selectedDateRange.endDate).format("DD/MM/YYYY")}</span>
          </div>
          {openDatePicker ? (
            <div
              style={{
                position: "absolute",
                zIndex: 99999,
                right: 0,
              }}
            >
              <DateRangeSelector
                analyticsHandler={(startDate, endDate) => {
                  fetchAnalytics(startDate, endDate);
                  fetchBioLinkAnalytics(startDate, endDate);
                }}
                toggleHandler={setOpenDatePicker}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="border d-flex p-5 pe-0 rounded-1">
        <div className="row w-100">
          <div className="col-lg-6">
            <div className="card card-xxl-stretch-50 h-100 mb-5 mb-xl-8 border-0 border-lg">
              <div className="card-body d-flex flex-column position-relative justify-content-between p-0">
                <div className="d-flex flex-column gap-lg-3 px-lg-9 pt-lg-9">
                  <div className="text-dark fw-bolder mt-lg-4 fs-2">
                    {totalPageViews}
                  </div>
                  <div>
                    <span className="text-gray-500 fw-semibold fs-4">
                      Link In Bio Views
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator mb-5 desktop-hide"></div>
          <div className="col-lg-6">
            <div className="card card-xxl-stretch-50 h-100 mb-5 mb-xl-8 border-0 border-lg">
              <div className="card-body d-flex flex-column position-relative justify-content-between p-0">
                <div className="d-flex flex-column gap-lg-3 px-lg-9 pt-lg-9">
                  <div className="text-dark fw-bolder mt-lg-4 fs-2">
                    {totalLinkClicks}
                  </div>
                  <div>
                    <span className="text-gray-500 fw-semibold fs-4">
                      Total Link Clicks
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body d-flex align-items-end px-0 pb-0">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "200px",
                }}
              >
                <Spinner />
              </div>
            ) : analyticsNotFound ? (
              <AnalyticsNotFound />
            ) : (
              <Chart date={date} ip={ip} />
            )}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="fw-bolder fs-2 mb-5">Link Activity</h3>
      </div>
      <div className="border-lg d-flex flex-column p-lg-5 rounded-1">
        <div className="d-flex align-items-center bg-light py-2 px-3 border">
          <i className="text-info far fa-arrow-pointer fs-4 me-3"></i>
          <h4 className="fs-3 mb-0">Total of {links?.length} Links</h4>
        </div>
        <div className="">
          <div className="table-responsive">
            <table className="table px-0 rounded-1 px-8 border-right-0 mb-0">
              <thead className="border-bottom">
                <tr>
                  <th className="ps-3 fs-5 fw-bold min-w-150px">
                    Button Label
                  </th>
                  <th className="fs-5 fw-bold w-300px">Destination</th>
                  <th className="fs-5 fw-bold">Short Link</th>
                  <th className="fs-5 fw-bold">Clicks</th>
                </tr>
              </thead>
              <tbody>
                {updatedLinks?.map((link) => {
                  const {
                    btnLabel,
                    destinationUrl,
                    slashTag,
                    brandedDomain,
                    lbClicks,
                  } = link;
                  return (
                    <tr className="bg-hover-light-info">
                      <td className="fs-6 ps-3">{btnLabel}</td>
                      <td className="fs-6">
                        <Link
                          target="_blank"
                          className="text-wrap-oneline mw-300px"
                          href={destinationUrl}
                        >
                          {destinationUrl}
                        </Link>
                      </td>
                      <td className="fs-6">{`https://${brandedDomain}/${slashTag}`}</td>
                      <td className="fs-6 text-center ">{lbClicks}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="fw-bolder fs-2 mb-5">Analytics</h3>
      </div>
      {analyticsNotFound ? (
        <AnalyticsNotFound />
      ) : (
        <>
          <div className="w-100">
            <div
              className="card w-100 rounded-1 card-flush h-xl-100 p-lg-5 "
              style={{ borderRadius: "15px" }}
            >
              <div className="card-header min-h-auto p-0 mobile-hide">
                <div className="card-title m-0 flex-column">
                  <h3 className="fw-bold mb-3">Country Views</h3>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table px-0 table-bordered px-8 border-right-0 mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th className="w-100 border-start-0 fs-5">Country</th>
                        <th className="fs-5">Views</th>
                        <th className="border-end-0 fs-5">%</th>
                      </tr>
                    </thead>
                    <tbody className="lastborder-bottom-0">
                      {countries &&
                        countries.length > 0 &&
                        countries?.map((item, index) => {
                          return (
                            <tr key={index} className="bg-hover-light-info">
                              <td className="border-start-0 fs-6">
                                {item?.name}
                              </td>
                              <td className="fs-6">{item?.clicks}</td>
                              <td className="border-end-0 fs-6">
                                {item?.percent}%
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mt-5">
            <div
              className="card w-100 rounded-1 card-flush h-xl-100 p-lg-5"
              style={{ borderRadius: "15px" }}
            >
              <div className="card-header min-h-auto p-0 mobile-hide">
                <div className="card-title m-0 flex-column">
                  <h3 className="fw-bold mb-4">City Views</h3>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table px-0 table-bordered px-8 border-right-0 mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th className="w-100 border-start-0 fs-5">City</th>
                        <th className="fs-5">Views</th>
                        <th className="border-end-0 fs-5">%</th>
                      </tr>
                    </thead>
                    <tbody className="lastborder-bottom-0">
                      {cities &&
                        cities.length > 0 &&
                        cities?.map((item, index) => {
                          return (
                            <tr key={index} className="bg-hover-light-info">
                              <td className="border-start-0 fs-6">
                                {item?.name}
                              </td>
                              <td className="fs-6">{item?.clicks}</td>
                              <td className="border-end-0 fs-6">
                                {item?.percent}%
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mt-5">
            <div
              className="card w-100 rounded-1 card-flush h-xl-100 p-lg-5"
              style={{ borderRadius: "15px" }}
            >
              <div className="card-header min-h-auto p-0 mobile-hide">
                <div className="card-title m-0 flex-column">
                  <h3 className="fw-bold mb-4">Device Views</h3>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table px-0 table-bordered px-8 border-right-0 mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th className="w-100 border-start-0 fs-5">Device</th>
                        <th className="fs-5">Views</th>
                        <th className="border-end-0 fs-5">%</th>
                      </tr>
                    </thead>
                    <tbody className="lastborder-bottom-0">
                      {devices &&
                        devices.length > 0 &&
                        devices?.map((item, index) => {
                          return (
                            <tr key={index} className="bg-hover-light-info">
                              <td className="border-start-0 fs-6">
                                {item?.name}
                              </td>
                              <td className="fs-6">{item?.clicks}</td>
                              <td className="border-end-0 fs-6">
                                {item?.percent}%
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Analytics;
