import ShorlinkEmptyState from "../../../assets/media/empty-state-link-list.png";
import QrEmptyState from "../../../assets/media/empty-state/empty-state-qr-code.png";

const NotSearchFound = ({ type }) => {
  const typesMapper = {
    qr: {
      h2: "No QR Found",
      p: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt, aperiam.",
      img: QrEmptyState,
    },
    shortlink: {
      h2: "No Link Found",
      p: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt, aperiam.",
      img: ShorlinkEmptyState,
    },
  };

  const { h2, p, img } = typesMapper[type];

  return (
    <div className="mobile-hide mt-20">
      <img
        className="opacity-50 img-fluid d-block mx-auto"
        src={img}
        alt={h2}
      />
      <div className="text-center mt-lg-10">
        <h2 className="fs-2x pt-5 pb-5 fw-bold">{h2}</h2>
        <p className="w-75 mx-auto fw-semibold fs-5 text-gray-500 mb-8">{p}</p>
      </div>
    </div>
  );
};

export default NotSearchFound;
