import dayjs from "dayjs";
import capitalizeFirstLetter from "../../utils/captilizeFirstLetter";
import { countries } from "countries-list";
import AdminAPI from "../../services/http/admin";
import Toggle from "react-toggle";
import { useState } from "react";
import { Spinner } from "reactstrap";

const Subscriber = ({
  subscriber,
  subscriberHandler,
  subscriberSelectorHandler,
}) => {
  const {
    firstName,
    lastName,
    username,
    createdAt = new Date(),
    country = "-",
    mobile = "-",
    ip = "-",
    plan,
    email = "-",
    isActive = false,
    id,
  } = subscriber || {};
  const [isLoading, setIsLoading] = useState(false);
  const [subScriberId, setSubScriberId] = useState("");
  const name = `${firstName !== null ? firstName[0].toUpperCase() + firstName.slice(1)  : ""} ${
    lastName !== null ? lastName : ""
  }`;

  const planDuration = plan?.split("-")[0];
  const planType = plan?.split("-")[1];
  const countryFlag = country?.toLowerCase();

  const disableHandler = async () => {
    try {
      setIsLoading(true);
      setSubScriberId(id);
      const response = await AdminAPI.updateUserStatus({
        id,
        isActive: !isActive,
      });
      if (!response?.data?.error) {
        subscriberHandler((preState) => {
          return preState?.map((item) =>
            item?.id === id
              ? { ...item, isActive: response?.data?.data?.isActive }
              : item
          );
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log("Error while changing user status: ", e);
      setIsLoading(false);
    }
  };
  
  return (
    <>
      <tr className="link-row border border-right-0 border-top-0 border-left-0">
        <td className="ps-6">
          <div className="form-check form-check-sm form-check-custom form-check-info form-check-solid rounded-1">
            <input
              className="form-check-input rounded-1"
              type="checkbox"
              checked={subscriber?.isSelected}
              onChange={() => subscriberSelectorHandler(id)}
            />
          </div>
        </td>
        
        <td className="text-start pe-0">
          <div className="text-gray-800 fw-bold my-auto d-block">{name}</div>
        </td>
        <td className="text-start pe-0">
          <div className="text-gray-800 fw-bold my-auto d-block">{email}</div>
        </td>
        <td>
          <div className="text-gray-800 fw-bold my-auto d-block">{mobile}</div>
        </td>
        <td>
          <div className="text-gray-800 fw-bold">
            {capitalizeFirstLetter(planType)}
          </div>
        </td>
        <td>
          <div className="text-gray-800 fw-bold">
            {capitalizeFirstLetter(planDuration)}
          </div>
        </td>
        <td>
          <div className="text-gray-800 fw-bold d-flex align-items-center gap-3">
            <div>
              <img
                style={{ height: "25px", width: "25px" }}
                src={`https://flagcdn.com/48x36/${countryFlag}.png`}
                alt="..."
                />
            </div>
                {countries[country]?.name || "-"}
          </div>
        </td>
        {/* <td>
          <div className="text-gray-800 fw-bold">
            {dayjs(createdAt).format("DD/MM/YY - HH:mm")}
          </div>
        </td> */}
        
        <td>
          <div className="text-gray-800 fw-bold">{ip}</div>
        </td>
        <td className="ps-0 cursor-pointer">
          {isLoading && subScriberId === id ? (
            <Spinner />
          ) : (
            <Toggle
              icons={false}
              checked={isActive}
              onChange={disableHandler}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default Subscriber;
