import * as yup from "yup";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GoogleLoginButton from "../../pages/googleLogin";
import AuthApi from "../../services/http/auth";
import AuthPageWrapper from "../wrapper/authPageWrapper";
import { Form, Input, FormGroup, Button, Spinner } from "reactstrap";
import { AuthContxt } from "../../store/authContxt";
import useCustomMessage from "../../hooks/useCustomMessage";
import { EMAIL_REGEX } from "../../utils/enum";
import { toast } from "react-toastify";
import  Swal from 'sweetalert2' 

const loginUpValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please fill this field")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const LoginMain = () => {
  const { message, messageHandler } = useCustomMessage();
  const { profileHandler } = useContext(AuthContxt);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(loginUpValidation),
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    if (data) {
      setLoading(true);
      try {
        const requestData = {
          email: data.email,
          password: data.password,
          clientType: "web",
        };

        const response = await AuthApi.signin(requestData);

        const responseData = response.data.data;
        if (response.data?.success) {
          if(response?.data?.sendForAdmin){
            // Swal.fire({
            //   title: "Sent!",
            //   text: "Reset password mail has been sent.",
            //   icon: "success",
            // });
          messageHandler("Reset password mail has been sent.", false);
            setLoading(false)
            return
          }
          if (responseData?.isAdmin) {
            profileHandler(responseData);
            localStorage.setItem("user_details", JSON.stringify(responseData));
            navigate("/");
            localStorage.setItem("auth_token", responseData.token);
          } else {
            toast.error("Only admins are Allowed");
          }
          setLoading(false);
        }
      } catch (e) {
        const errType = e?.response?.data?.message;
        if (errType === "Registered with another method.") {
          messageHandler("This mail is registered with another method", false);
        } else {
          messageHandler(errType, false);
        }
        setLoading(false);
      }
    }
  };

  return (
    <AuthPageWrapper>
      <Form
        onSubmit={handleSubmit(handleLogin)}
        className="px-6 position-relative z-1"
      >
        <div className="text-lg-center mt-lg-0 mt-8 mb-7">
          <h1 className="text-dark mb-3 fs-3x fw-bolder">Sign in to Divsly</h1>
          <div className="text-gray-400 fw-semibold fs-4">
            Create, Share and Grow
          </div>
          <div className="flex-center btn-transparent btn-lg w-100 h-55px mt-lg-10 mt-5 rounded-1"></div>
        </div>
        <div className="fv-row mb-7 fv-plugins-icon-container">
          <FormGroup>
            <div className="position-relative">
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    value={value}
                    className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid mb-2 pe-15 rounded-55rem"
                    type="email"
                    placeholder="Enter your email"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
              <span
                className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
                data-kt-password-meter-control="visibility"
              >
                <i className="fa-regular fa-envelope fs-2"></i>
              </span>
            </div>
            {errors?.email && (
              <span className="error-text text-danger">
                {errors?.email?.message}
              </span>
            )}
          </FormGroup>
        </div>
        <div className="mb-3 mt-4 fv-row fv-plugins-icon-container">
          <FormGroup>
            <div className="position-relative mb-2">
              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    value={value}
                    className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid pe-15 rounded-55rem"
                    type="password"
                    placeholder="Enter your password"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
              <span
                className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
                data-kt-password-meter-control="visibility"
              >
                <i className="fa-regular fa-lock fs-2"></i>
              </span>
            </div>
            {errors?.password && (
              <span className="error-text text-danger">
                {errors?.password?.message}
              </span>
            )}
          </FormGroup>
        </div>

        <div className="d-flex justify-content-end mb-7">
          <Link to="/forgotPassword" className="link-primary fs-6 fw-bold">
            Forgot password?
          </Link>
        </div>

        <div className="d-flex justify-content-end mb-3">
          {message ? message : null}
        </div>

        <div>
          <Button
            color="info"
            className="btn btn-lg btn-info bg-brand w-100 h-55px mb-5 rounded-55rem btn-hover-dark"
            disabled={loading}
            type="submit"
          >
            {loading ? <Spinner /> : "Continue"}
          </Button>
        </div>
      </Form>
    </AuthPageWrapper>
  );
};

export default LoginMain;
