import * as yup from "yup";
import { SLASH_TAG_REGEX, URL_REGEX } from "../enum";
import { APP_ROUTES, BLOCKED_KEYWORDS } from "../../config/constants";

export const createLinkValidation = yup.object().shape({
  destinationUrl: yup
    .string()
    // .url("Enter a valid URL")
    .matches(URL_REGEX, "Enter a valid URL")
    .required("Destination URL is required")
    .test('is-not-in-list', 'Restricted Content', value => {
      if (!value) return true; // Skip validation if value is not provided (handled by required)
      const valueLowerCase = value.toLowerCase();
      return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
    }),
  title: yup.string()
  .test('is-not-in-list', 'Restricted Content', value => {
    if (!value) return true; // Skip validation if value is not provided (handled by required)
    const valueLowerCase = value.toLowerCase();
    return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
  }),
  slashTag: yup.string()
    .test('is-not-in-list', 'Restricted Slash tag', value => {
      if (!value) return true; // Skip validation if value is not provided (handled by required)
      const valueLowerCase = value.toLowerCase();
      return !APP_ROUTES.includes(valueLowerCase);
    })
    // .matches(SLASH_TAG_REGEX, {
    //   message: "The characters `~,<>;:'\"/\\[\\]^{}()=+!*@&$?%#|` are not allowed",
    //   excludeEmptyString: true,
    // })
    // .matches(/^\S*$/, {
    //   message: "Space is not allowed in slash tag",
    //   excludeEmptyString: true,
    // })
    .required("Slash tag is required!")
    .matches(SLASH_TAG_REGEX, "The characters `~,<>;:'\"/\\[\\]^{}()=+!*@&$?%#|` are not allowed")
    .max(50, "Slash tag can't be longer than 50 characters"),
  brandedDomain: yup.string().required("Branded domain is required"),
});

export const updateLinkValidation = yup.object().shape({
  destinationUrl: yup
    .string()
    // .url("Enter a valid URL")
    .matches(URL_REGEX, "Enter a valid URL")
    .test('is-not-in-list', 'Restricted Content', value => {
      if (!value) return true; // Skip validation if value is not provided (handled by required)
      const valueLowerCase = value.toLowerCase();
      return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
    })
    .required("Destination url is required"),
  title: yup.string().required("Title is required")
  .test('is-not-in-list', 'Restricted Content', value => {
    if (!value) return true; // Skip validation if value is not provided (handled by required)
    const valueLowerCase = value.toLowerCase();
    return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
  }),
});

