import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { FormGroup, Label, Input } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { getCountryFlag } from "../../../../utils/getCountryFlag";
import UserSettingsApi from "../../../../services/http/userSettings";
import { UserSettingsContxt } from "../../userSettingContxt";
import getMappedCountriesData from "../../../../utils/getMappedCountriesData";
import { countries as CountiresPackage } from "countries-list";
import axios from "axios";

const newUserValidation = yup.object().shape({
  firstName: yup.string().required("Please fill this field!"),
  lastName: yup.string().required("Please fill this field!"),
  country: yup.string().required("Please fill this field!"),
  addressLine1: yup.string().required("Please fill this field!"),
  addressLine2: yup.string().required("Please fill this field!"),
  town: yup.string().required("Please fill this field!"),
  state: yup.string().required("Please fill this field!"),
  postCode: yup
    .string()
    .required()
    .matches(/^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/, "Enter valid postal code!"),
});

const AddressForm = ({ edittedAddress, toggleHandler, addressHandler }) => {
  const { billingAddressHandler } = useContext(UserSettingsContxt);

  const isEdit = edittedAddress !== null;
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const [countries] = useState(getMappedCountriesData());
  const [fetchedCountryName, setFetchedCountryName] = useState("");
  const [fetchedCountryCode, setFetchedCountryCode] = useState("");

  const form = useForm({
    resolver: yupResolver(newUserValidation),
    defaultValues: {
      firstName: "",
      lastName: "",
      country: "",
      addressLine1: "",
      addressLine2: "",
      town: "",
      state: "",
      postCode: "",
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
    trigger,
  } = form;
  const { country } = watch();

  const messageHandler = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const submitHandler = async (data) => {
    if (data) {
      try {
        setLoading(true);
        const response = isEdit
          ? await UserSettingsApi.updateAddress(edittedAddress?.id, data)
          : await UserSettingsApi.createAddress(data);
        if (response?.data?.error) {
          setMessage(response.data?.message || "Something went wrong");
          setLoading(false);
        } else {
          if (isEdit) {
            addressHandler("update", null);
          }
          billingAddressHandler(
            isEdit ? "update" : "add",
            response?.data?.data
          );
          setLoading(false);
          reset();
          toggleHandler();
        }
      } catch (e) {
        messageHandler(e?.response?.data?.message || "Something went wrong");
        setLoading(false);
        console.log("Error while creating address: ", e);
      }
    }
  };

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  const searchedCountries = useMemo(() => {
    if (search) {
      return countries.filter((item) =>
        item?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }
    return countries;
  }, [search, countries]);

  useEffect(() => {
    if (isEdit && edittedAddress) {
      reset({
        firstName: edittedAddress?.firstName,
        lastName: edittedAddress?.lastName,
        country: edittedAddress?.country,
        addressLine1: edittedAddress?.addressLine1,
        addressLine2: edittedAddress?.addressLine2,
        state: edittedAddress?.state,
        postCode: edittedAddress?.postCode,
        town: edittedAddress?.town,
      });
    }
  }, [edittedAddress, isEdit, reset]);

  const fetchCountry = async () => {
    try {
      const response = await axios.get("https://ipinfo.io/json");
      const countryCode = response.data.country.toUpperCase();
      const countryName = CountiresPackage[countryCode]?.name || "Unknown Country";
      setFetchedCountryName(countryName);
      setFetchedCountryCode(countryCode);
    } catch (err) {
      console.error("Error fetching country code:", err);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);
  useEffect(() => {
    if (fetchedCountryCode && fetchedCountryName) {
      setValue("country", fetchedCountryCode);
      trigger("country");
    }
  }, [fetchedCountryCode, fetchedCountryName, setValue, trigger]);

  return (
    <Modal
      className="modal-lg modal-dialog-centered addressmodal-billing"
      isOpen={true}
      toggle={toggleHandler}
    >
      <Form onSubmit={handleSubmit(submitHandler)}>
        <ModalHeader
          className="px-lg-10 pt-lg-10 px-6 pt-6"
          toggle={toggleHandler}
        >
          <h2 className="mb-0">
            {isEdit ? "Update Address" : "Add New Address"}
          </h2>
        </ModalHeader>
        <ModalBody className="px-lg-15 px-6 pb-lg-10 pb-3">
          <div className="overflow-auto me-n7 pe-7 ">
            <div className="row mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <FormGroup>
                  <Label className="fs-5 fw-bold mb-2 required">
                    First Name
                  </Label>
                  <Controller
                    name={"firstName"}
                    control={control}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Input
                        value={value}
                        type="text"
                        className="form-control h-50px border-gray-250 mb-3 mb-lg-0 rounded-1 border"
                        placeholder=""
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormGroup>
                {errors.firstName ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.firstName.message}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <FormGroup>
                  <Label className="fs-5 fw-bold mb-2 required">
                    Last Name
                  </Label>
                  <Controller
                    name={"lastName"}
                    control={control}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Input
                        value={value}
                        type="text"
                        className="form-control h-50px border-gray-250 mb-3 mb-lg-0 rounded-1 border"
                        placeholder=""
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormGroup>
                {errors.lastName ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.lastName.message}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
              <div className="fs-5 fw-bold mb-2 required">Country</div>
              <UncontrolledDropdown direction="down">
                <DropdownToggle
                  name="country"
                  aria-label="Select a Country"
                  data-placeholder="Select a country..."
                  className="form-select form-select-solid form-select-lg fw-semibold"
                >
                  {country ? (
                    <div style={{ textAlign: "start" }}>
                      <img
                        src={getCountryFlag(country) || "/no-image.png"}
                        alt={country}
                        className="h-20px me-2"
                      />
                      {CountiresPackage[country]?.name || fetchedCountryName}
                    </div>
                  ) : (
                    <span>{"Select an option"}</span>
                  )}
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    maxHeight: "300px",
                    width: "100%",
                    overflow: "scroll",
                  }}
                >
                  <div
                    style={{
                      padding: "0.5rem 1.25rem",
                      margin: "0 0 0.5rem 0",
                    }}
                  >
                    <Input
                      value={search}
                      onChange={searchHandler}
                      style={{
                        backgroundColor: "#ffffff",
                        padding: "0.55rem 0.75rem",
                        color: "#5e6278",
                        fontSize: "0.95rem",
                        border: "1px solid #e1e3ea",
                        borderRadius: "0.35rem",
                        outline: "0 !important",
                      }}
                    />
                  </div>
                  <div>
                    {searchedCountries?.map(({ name, code }) => {
                      return (
                        <DropdownItem
                          key={code}
                          value={code}
                          onClick={() => {
                            setValue("country", code);
                            trigger("country");
                          }}
                        >
                          <img
                            src={getCountryFlag(code) || "/no-image.png"}
                            className="h-20px me-2"
                            alt={name}
                          />
                          {name}
                        </DropdownItem>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              {errors.country ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.country.message}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2 required">
                  Address Line 1
                </Label>
                <Controller
                  name={"addressLine1"}
                  control={control}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Input
                      value={value}
                      type="text"
                      className="form-control h-50px border-gray-250 mb-3 mb-lg-0 rounded-1 border"
                      placeholder=""
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormGroup>
              {errors.addressLine1 ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.addressLine1.message}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2 required">
                  Address Line 2
                </Label>
                <Controller
                  name={"addressLine2"}
                  control={control}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Input
                      value={value}
                      type="text"
                      className="form-control h-50px border-gray-250 mb-3 mb-lg-0 rounded-1 border"
                      placeholder=""
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormGroup>
              {errors.addressLine2 ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.addressLine2.message}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="row g-9 mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <FormGroup>
                  <Label className="fs-5 fw-bold mb-2 required">Town</Label>
                  <Controller
                    name={"town"}
                    control={control}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Input
                        value={value}
                        type="text"
                        className="form-control h-50px border-gray-250 mb-3 mb-lg-0 rounded-1 border"
                        placeholder=""
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormGroup>
                {errors.town ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.town.message}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="row g-9 mb-lg-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <FormGroup>
                  <Label className="fs-5 fw-bold mb-2 required">State</Label>
                  <Controller
                    name={"state"}
                    control={control}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Input
                        value={value}
                        type="text"
                        className="form-control h-50px border-gray-250 border rounded-1"
                        placeholder=""
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormGroup>
                {errors.state ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.state.message}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <FormGroup>
                  <Label className="fs-5 fw-bold mb-2 required">
                    Post Code
                  </Label>
                  <Controller
                    name={"postCode"}
                    control={control}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Input
                        value={value}
                        type="text"
                        className="form-control h-50px border-gray-250 border rounded-1"
                        placeholder=""
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormGroup>
                {errors.postCode ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.postCode.message}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="p-lg-10 p-5">
          <div
            style={{
              textAlign: "start",
            }}
          >
            {message ? (
              <span className="text-danger fs-6 fw-bold">{message}</span>
            ) : null}
          </div>
          <div className="d-flex flex-end">
            <button
              type="reset"
              onClick={toggleHandler}
              className="btn btn-light w-lg-150px me-3 border rounded-1 me-4"
            >
              Discard
            </button>
            <button type="submit" className="btn btn-info w-lg-150px rounded-1">
              {loading ? <Spinner /> : isEdit ? "Update" : "Save"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddressForm;
