import React, { useEffect, useState } from "react";
import UserLinksHeader from "./header";
import AdminAPI from "../../services/http/admin";
import emptyScreen from "../../assets/media/empty-state/analytics.svg";
import { Spinner } from "reactstrap";
import TableHeader from "./tableHeader";
import Urls from "./urls";

const UserLinks = () => {
  const [loading, setLoading] = useState(false);
  const [userUrls, setUserUrls] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const urlsSelectorHandler = (id) => {
    setUserUrls((preState) => {
      const updatedSubscribers = preState?.map((item) => {
        const updatedItem =
          item?.id === id
            ? {
                ...item,
                isSelected: item?.isSelected ? !item?.isSelected : true,
              }
            : item;
        return updatedItem;
      });
      return updatedSubscribers;
    });
  };

  const fetchUrls = async (page = 1, filters = {}) => {
    if (search) {
      filters.search = search;
    }
    filters.page = page;

    try {
      setLoading(true);
      const response = await AdminAPI.getUserLinks(filters);
      if (!response?.data?.error) {
        const newUrls = response?.data?.data?.shortLink || [];
        if (page === 1) {
          setUserUrls(newUrls);
        } else {
          setUserUrls((prevUrls) => [...prevUrls, ...newUrls]);
        }
        setHasMore(newUrls.length > 0);
      }
      setLoading(false);
    } catch (e) {
      console.log("Error while fetching subscribers: ", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1); 
    fetchUrls(1);
  }, [search]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        === document.documentElement.offsetHeight
      ) {
        if (!loading && hasMore) {
          setPage((prevPage) => {
            const newPage = prevPage + 1;
            fetchUrls(newPage);
            return newPage;
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore, search]);

  const allUrlsSelectorHandler = (isSelected) => {
    setUserUrls((preState) => {
      return preState?.map((item) => ({
        ...item,
        isSelected: isSelected,
      }));
    });
  };

  return (
    <>
      <div className="card card-flush rounded-sm-0 card">
        <UserLinksHeader
          userUrls={userUrls}
          fetchUrls={fetchUrls}
          setSearch={setSearch}
        />
        <div
          className="card-body px-6 px-lg-10 pt-0 overflow-x-auto  card-body"
          style={{ minHeight: "200px" }}
        >
          <div className="w-100 shortlinktableouterdiv overflow-x-auto">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table align-middle fs-6 gy-5 border-bottom">
                  <TableHeader
                    allUrlsSelectorHandler={allUrlsSelectorHandler}
                  />
                  <tbody className="fw-semibold text-gray-600">
                    {loading ? (
                      <tr>
                        <td colSpan={9}>
                          <div className="text-center">
                            <Spinner />
                          </div>
                        </td>
                      </tr>
                    ) : userUrls && userUrls.length > 0 ? (
                      userUrls.map((item) => (
                        <Urls
                          setLoading={setLoading}
                          tableData={item}
                          key={item.id}
                          urlsHandler={setUserUrls}
                          urlsSelectorHandler={urlsSelectorHandler}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9}>
                          <div className="text-center">
                            <img
                              src={emptyScreen}
                              className="w-lg-300px w-75 opacity-75 mb-10"
                              alt=""
                            />
                            <h2 className="mx-10 opacity-75">
                              No Subscribers Found !
                            </h2>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLinks;
