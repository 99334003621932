import React, { useContext } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./all.min.css";
import RestrictLoginPage from "./RestrictLoginPage";
import routes from "./pages/routes";
import AuthLayout from "./AuthLayout";
import Page from "./pages/[slug]";
import { AuthContxt } from "./store/authContxt";
import { USER_TYPE } from "./utils/enum";

const checkUserAuthentication = () => {
  let localData = localStorage.getItem("user_details");
  let local = localData;
  return JSON.parse(local);
};

function App() {
  const { profile } = useContext(AuthContxt);
  const isAuthenticated = checkUserAuthentication();

  const ROUTES =
    isAuthenticated?.isAdmin || profile?.isAdmin
      ? routes["adminRoutes"]
      : routes["routes"];

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/:slug" element={<Page />} />
        <Route element={<RestrictLoginPage />}>
          {routes["noAuthRoutes"].map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            );
          })}
        </Route>
        <Route element={<AuthLayout />}>
          {ROUTES.map((route, index) => {
            if (
              route.path === "/user-settings" &&
              profile?.userType === USER_TYPE.USER
            ) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<Navigate to="/dashboard" />}
                />
              );
            }
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <div className="grid">
                    <route.component />
                  </div>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </>
  );
}

export default App;
