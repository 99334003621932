import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Layout0 from "../create-page/layout0";
import Layout1 from "../create-page/layout1";
import Layout2 from "../create-page/layout2";
import Layout3 from "../create-page/layout3";
import Layout4 from "../create-page/layout4";

const LinkInBioCard = ({ linkInBio, deleteHandler }) => {
  const { pageDomain, pageLink, createdAt, views, id } = linkInBio || {};
  
  const templateMapper = {
    0: <Layout0 values={linkInBio} />,
    1: <Layout1 values={linkInBio} />,
    2: <Layout2 values={linkInBio} />,
    3: <Layout3 values={linkInBio} />,
    4: <Layout4 values={linkInBio} />,
  };
  console.log(linkInBio?.template, 'bkjjg')
  return (
    <>
      <div className="col-lg-4 col-12">
        <div className="card card-flush cursor-pointer p-5 border border-gray-300 rounded-1 h-auto">
          <div className="d-flex align-items-center position-relative overflow-hidden">
            <div className="preview-container display-block">
              <div className="frame-preview">
                {templateMapper[linkInBio?.template]}
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-between ps-lg-8 ps-5 pt-0 pb-0 pe-0"
              style={{ height: "11rem" }}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <Link
                    to={`https://${pageDomain}/${pageLink}`}
                    target="_blank"
                  >
                    <h3 className="card-title text-dark on-hover-underline text-wrap-oneline autowidthmobile fs-lg-20px mb-3">{`${pageDomain}/${pageLink}`}</h3>
                  </Link>
                </div>
                {/* <p className="fs-lg-6 fs-7 fw-bold text-primary mt-lg-3 mt-2 mb-lg-2 mb-3 updatedlinkinbiotime">
                {`Updated ${getUpdatedTime()}`}
              </p> */}
                <div className="d-flex align-items-center">
                  <i
                    className="fal fa-calendar fs-5 me-2"
                    style={{ color: "#c0c4db" }}
                  ></i>
                  <span className="fs-8">
                    {dayjs(createdAt).format("MMM DD, YYYY")}
                  </span>
                </div>
                <div className="d-flex align-items-center mt-lg-3 mt-2">
                  <i
                    className="fal fa-eye fs-5 me-2"
                    style={{ color: "#c0c4db" }}
                  ></i>
                  <span className="fs-8">{`${views || 0} Views`}</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Link
                  className="btn btn-icon bg-light-info rounded-1 me-4"
                  to={`/create-link-in-bio/${id}`}
                >
                  <i className="fa-regular fa-pen-to-square text-info fs-3"></i>
                </Link>
                <button className="btn btn-icon bg-light-info rounded-1">
                  <i
                    className="fa-regular fa-trash-can text-info fs-3"
                    onClick={() => deleteHandler(id)}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkInBioCard;
