import React, { useCallback, useRef, useState, useEffect } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  addEdge,
  updateEdge,
} from 'react-flow-renderer';
import 'react-flow-renderer/dist/style.css';
import { SourceNode, TargetNode } from './CustomNode';  // Adjust path as necessary

const initialNodes = [
  {
    id: '1',
    type: 'sourceNode',
    data: { label: 'First Name' },
    position: { x: 100, y: 0 },
  },
  {
    id: '2',
    type: 'sourceNode',
    data: { label: 'Last Name' },
    position: { x: 100, y: 60 },
  },
  {
    id: '3',
    type: 'sourceNode',
    data: { label: 'Email' },
    position: { x: 100, y: 120 },
  },
  {
    id: '4',
    type: 'sourceNode',
    data: { label: 'Mobile' },
    position: { x: 100, y: 180 },
  },
  {
    id: '5',
    type: 'sourceNode',
    data: { label: 'Job' },
    position: { x: 100, y: 240 },
  },
  {
    id: '6',
    type: 'sourceNode',
    data: { label: 'Location' },
    position: { x: 100, y: 300 },
  },
  {
    id: '7',
    type: 'targetNode',
    data: { label: 'Name' },
    position: { x: 500, y: 0 },
  },
  {
    id: '8',
    type: 'targetNode',
    data: { label: 'Email' },
    position: { x: 500, y: 60 },
  },
  {
    id: '9',
    type: 'targetNode',
    data: { label: 'Work Email' },
    position: { x: 500, y: 120 },
  },
  {
    id: '10',
    type: 'targetNode',
    data: { label: 'Job' },
    position: { x: 500, y: 180 },
  },
  {
    id: '11',
    type: 'targetNode',
    data: { label: 'Location' },
    position: { x: 500, y: 240 },
  },
  {
    id: '12',
    type: 'targetNode',
    data: { label: 'Mobile Number' },
    position: { x: 500, y: 300 },
  },
  {
    id: '13',
    type: 'targetNode',
    data: { label: 'WhatsApp Number' },
    position: { x: 500, y: 360 },
  },
  {
    id: '14',
    type: 'targetNode',
    data: { label: 'City' },
    position: { x: 500, y: 420 },
  },
];
const initialEdges = [];

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, style }) => (
  <path
    id={id}
    style={style}
    d={`M ${sourceX},${sourceY} L ${targetX},${targetY}`}
    markerEnd="url(#arrowhead)"
  />
);

const DeleteEdgeDrop = () => {
  const edgeReconnectSuccessful = useRef(true);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [nextNodeId, setNextNodeId] = useState(() => {
    const maxId = nodes.reduce((max, node) => Math.max(max, parseInt(node.id, 10)), 0);
    return maxId + 1;
  });
  const [nodeBeingEdited, setNodeBeingEdited] = useState(null);

  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;

      // Check if the target node already has a connection
      const targetEdges = edges.filter((edge) => edge.target === target);

      if (targetEdges.length < 1) {
        setEdges((els) => addEdge(params, els));
      } else {
        console.log('Target node already has a connection');
      }
    },
    [edges, setEdges]
  );

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeReconnectSuccessful.current = true;
    setEdges((eds) => eds.map((edge) => (edge.id === oldEdge.id ? { ...edge, ...newConnection } : edge)));
  }, []);

  const onEdgeUpdateStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    edgeReconnectSuccessful.current = true;
  }, []);

  const handleAddNode = () => {
    // Find the highest Y position of source nodes
    const lastSourceNode = nodes
      .filter((node) => node.type === 'sourceNode')
      .reduce((last, node) => (node.position.y > last.position.y ? node : last), { position: { y: 0 } });

    const newNode = {
      id: `${nextNodeId}`,
      type: 'sourceNode',
      data: {
        label: `New Node ${nextNodeId}`,
        onUpdateLabel: (id, newLabel) => {
          setNodes((nds) => nds.map((node) => (node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node)));
        },
      },
      position: { x: 100, y: lastSourceNode.position.y + 60 }, // Position below the last source node
    };

    setNodes((nds) => [...nds, newNode]);
    setNextNodeId(nextNodeId + 1);
  };

  const onNodeEdit = (id) => {
    setNodeBeingEdited(id);
  };

  useEffect(() => {
    setNextNodeId(nodes.reduce((maxId, node) => Math.max(maxId, parseInt(node.id, 10)), 0) + 1);
  }, [nodes]);

  return (
    <>
      <input type='text' className='form-control mb-3' placeholder='Add your tags here with comma separator' />
      <button className='btn btn-info px-5 py-3' onClick={handleAddNode}>Add Source Node</button>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        snapToGrid
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnect={onConnect}
        fitView
        attributionPosition="top-right"
        nodeTypes={{ sourceNode: SourceNode, targetNode: TargetNode }}  // Register custom node types
        edgeTypes={{ custom: CustomEdge }}  // Register custom edge type
        onNodeDoubleClick={(event, node) => {
          const nodeId = node.id;
          if (nodeId !== nodeBeingEdited) {
            setNodeBeingEdited(nodeId);
          } else {
            setNodeBeingEdited(null);
          }
        }}
      >
        <Controls />
        <defs>
          <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="10"
            refX="0"
            refY="3"
            orient="auto"
            fill="purple"
          >
            <path d="M 0,0 L 10,3 L 0,6 L 4,3 Z" />
          </marker>
        </defs>
      </ReactFlow>
    </>
  );
};

export default DeleteEdgeDrop;
