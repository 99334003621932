import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import convertToK from "../../../utils/convertToK";

const DonutChart = ({ data }) => {
  const chartRef = useRef(null);
  console.log(data)

  useEffect(() => {
    if (chartRef.current) {
      const options = {
        chart: {
          type: "donut",
          width: 300,
          fontFamily: "inherit",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return convertToK(+val || 0);
            },
          },
        },
        colors: ["#00A3FF", "#50CD89", "#8500ff"],
        series: data,
        labels: ["Standard", "Super", "Starter",],
        legend: {
          show: false,
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return <div ref={chartRef}></div>;
};

export default DonutChart;
