import React, { useCallback, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import dayjs from "dayjs";
import _debounce from "lodash/debounce";
import DateRangeSelector from "./dateRange";

const UserLinksHeader = ({ fetchUrls, userUrls, setSearch }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),  
    endDate: new Date(),
  });

  const [showSearch, setShowSearch] = useState(false);

  const filtersHandler = (filter) => {
    fetchUrls(1,{ ...filter });
  };

  // User List Download
  const headers = [
    { label: "Id", key: "id" },
    { label: "selectedDateRange Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "User Name", key: "username" },
    { label: "Created At", key: "createdAt" },
    { label: "Country", key: "country" },
    { label: "Mobile", key: "mobile" },
    { label: "I.P.", key: "ip" },
    { label: "Plan", key: "plan" },
    { label: "Email", key: "email" },
    { label: "Active ", key: "isActive" },
  ];

  return (
    <div className="px-10 py-10 d-flex justify-content-between w-100">
      <h2 className="me-15 fs-2x">Links</h2>
      <div>
        <div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons">
          <InputGroup className="mb-0 w-auto">
            {showSearch ? (
              <Form.Control
                className="headerinputsearch fw-normal"
                placeholder="Search here ..."
                aria-label="Search here ..."
                aria-describedby="basic-addon2"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></Form.Control>
            ) : null}
            <InputGroup.Text
              id="basic-addon2"
              title="search"
              className="cursor-pointer btn btn-icon rounded-1 bg-light-info"
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            >
              <i className="fa-regular fa-magnifying-glass text-info fs-3"></i>
            </InputGroup.Text>
          </InputGroup>


          {/* <Dropdown className="d-inline nospce">
            <Dropdown.Toggle
              id="dropdown-autoclose-false"
              className="btn btn-icon bg-light-info rounded-1"
              variant="bg-light"
            >
              <i className="fa-solid fa-filter text-info fs-3"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-3">
              <div
                style={
                  filters?.sort === "latest"
                    ? { backgroundColor: "skyblue" }
                    : {}
                }
                className="menu-item px-2 bg-hover-light-info"
                onClick={() => filtersHandler({ sort: "latest" })}
              >
                Latest
              </div>
              <div
                style={
                  filters?.sort === "oldest"
                    ? { backgroundColor: "skyblue" }
                    : {}
                }
                className="menu-item px-2 bg-hover-light-info"
                onClick={() => filtersHandler({ sort: "oldest" })}
              >
                Oldest
              </div>
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Dropdown className="d-inline nospce">
            <Dropdown.Toggle
              id="dropdown-autoclose-true"
              className="btn btn-icon bg-light-info rounded-1"
              variant="bg-light"
            >
              <i className="fa-solid fa-arrow-up-wide-short text-info fs-3"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-3">
              <div
                style={
                  filters?.filter === "paid"
                    ? { backgroundColor: "skyblue" }
                    : {}
                }
                className="menu-item px-2 bg-hover-light-info"
                onClick={() => filtersHandler({ filter: "paid" })}
              >
                Paid
              </div>
              <div
                style={
                  filters?.filter === "free"
                    ? { backgroundColor: "skyblue" }
                    : {}
                }
                className="menu-item px-2 bg-hover-light-info"
                onClick={() => filtersHandler({ filter: "free" })}
              >
                Free
              </div>
              <div className="menu-item px-2 bg-hover-light-info">Disabled</div>
              <div
                onMouseOver={() => setSubscriptionHover(true)}
                onMouseOut={() => setSubscriptionHover(false)}
              >
                <div className="menu-item px-2 bg-hover-light-info">
                  Subscription
                </div>
                {subscriptionHover ? (
                  <>
                    <div
                      style={
                        filters?.filter === "starter"
                          ? { backgroundColor: "skyblue" }
                          : {}
                      }
                      onClick={() =>
                        filtersHandler({
                          filter: "starter",
                          type: "subscription",
                        })
                      }
                      className="menu-item px-2 bg-hover-light-info"
                    >
                      Starter
                    </div>
                    <div
                      style={
                        filters?.filter === "standard"
                          ? { backgroundColor: "skyblue" }
                          : {}
                      }
                      onClick={() =>
                        filtersHandler({
                          filter: "standard",
                          type: "subscription",
                        })
                      }
                      className="menu-item px-2 bg-hover-light-info"
                    >
                      Standard
                    </div>
                    <div
                      style={
                        filters?.filter === "super"
                          ? { backgroundColor: "skyblue" }
                          : {}
                      }
                      onClick={() =>
                        filtersHandler({
                          filter: "super",
                          type: "subscription",
                        })
                      }
                      className="menu-item px-2 bg-hover-light-info"
                    >
                      Super
                    </div>
                  </>
                ) : null}
              </div>
              <div
                onMouseOver={() => setDurationHover(true)}
                onMouseOut={() => setDurationHover(false)}
              >
                <div className="menu-item px-2 bg-hover-light-info">
                  Payment Mode
                </div>
                {durationHover ? (
                  <>
                    <div
                      onClick={() =>
                        filtersHandler({
                          filter: "monthly",
                          type: "payment",
                        })
                      }
                      className="menu-item px-2 bg-hover-light-info"
                    >
                      Monthly
                    </div>
                    <div
                      onClick={() =>
                        filtersHandler({
                          filter: "yearly",
                          type: "payment",
                        })
                      }
                      className="menu-item px-2 bg-hover-light-info"
                    >
                      Yearly
                    </div>
                  </>
                ) : null}
              </div>
            </Dropdown.Menu>
          </Dropdown> */}

          <div className="mt-5 mt-lg-0">
            <div
              onClick={() => setOpenDatePicker(!openDatePicker)}
              class="form-control form-control-solid w-lg-250px justify-content-between"
              style={{
                position: "relative",
              }}
            >
              <span>
                {dayjs(selectedDateRange.startDate).format("DD/MM/YYYY")}
              </span>
              <span>{` - `}</span>
              <span>
                {dayjs(selectedDateRange.endDate).format("DD/MM/YYYY")}
              </span>
            </div>
            {openDatePicker ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 99999,
                  right: 0,
                }}
              >
                <DateRangeSelector
                  analyticsHandler={(startDate, endDate) => {
                    filtersHandler({ startDate, endDate });
                  }}
                  toggleHandler={setOpenDatePicker}
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLinksHeader;
