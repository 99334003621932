import { countries, getCountryData } from "countries-list";
import { useForm } from "react-hook-form";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone";
import * as yup from "yup";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AvatarCropper from "../../../link-in-bio/customization/avatarCropper";
import UserSettingsApi from "../../../../services/http/userSettings";
import { getCountryFlag } from "../../../../utils/getCountryFlag";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PHONE_REGEX } from "../../../../utils/enum";
import generateProfileImage from "../../../../utils/generateProfileImage";
import { AuthContxt } from "../../../../store/authContxt";
import axios from "axios";

const DEFAULT_VALUES = {
  avatar: "",
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  country: "",
};

const DetailForm = ({
  detailFormHandler,
  edittedProfile = null,
  profileHandler,
}) => {
  const isEdit = edittedProfile !== null;
  const fileInputRef = useRef(null);
  const { profile } = useContext(AuthContxt)
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();
  const [cropperModal, setCropperModal] = useState(false);

  const [phoneRegex, setPhoneRegex] = useState('');

  const updateProfileValidation = yup.object().shape({
    firstName: yup.string().required("Please fill this field!"),
    lastName: yup.string(),
    country: yup.string().required("Please fill this field!"),
    mobile: yup
      .string()
      .required()
      .matches(phoneRegex, `Please enter valid phone no.`),
  });

  const form = useForm({
    resolver: yupResolver(updateProfileValidation),
    defaultValues: DEFAULT_VALUES,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = form;
  const { avatar, firstName, lastName, country, mobile } = watch();
  const userCountry = country ? getCountryData(country) : "";

  const submitHandler = async (formData) => {
    try {
      setLoading(true);
      const updatedPayload = { ...formData, mobile: formData?.mobile + "" };
      const { data } = await UserSettingsApi.updateProfile(updatedPayload);
      profileHandler(data?.data);
      reset();
      detailFormHandler();
      setLoading(false);
    } catch (e) {
      console.log("Error while updating profile: ", e);
      setLoading(false);
    }
  };

  const handlePenClick = () => {
    fileInputRef?.current?.click();
  };

  const resetAvatarHandler = () => {
    setFile(null);
    setValue("avatar", generateProfileImage(profile?.email));
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return null;
    }
    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        const reader = new FileReader();
        reader.onload = function () {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.readAsDataURL(file);
      }, "image/png");
    });
  };

  const showCroppedImage = useCallback(async (image, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setValue("avatar", croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleFileChange = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        let img = new Image();
        img.onload = () => {
          const base64String = reader.result;
          setValue("avatar", base64String);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (edittedProfile) {
      reset({ ...edittedProfile });
    }
  }, [edittedProfile, reset, setValue]);

  const cropModalHandler = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setUploadedImage(base64String);
        setCropperModal(true);
      };
      reader.readAsDataURL(selectedFile);
    }
    event.target.value = "";
  };

  return (
    <>
      <div id="kt_account_settings_profile_details" className={"d-block"}>
        <form
          id="kt_account_profile_details_form"
          className="form"
          onSubmit={handleSubmit(submitHandler)}
        >
          <div className="card-body p-1">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fs-6 pt-0">
                Avatar
              </label>
              <div className="col-lg-8">
                <div className="image-input image-input-outline">
                  <div className="image-input-wrapper w-125px h-125px p-3 d-flex justify-content-center align-items-center">
                    <img
                      src={avatar || "/placeholder.png"}
                      alt="Logo"
                      height={85}
                      width={85}
                    />
                  </div>

                  <div className="position-relative">
                    <input
                      ref={fileInputRef}
                      type="file"
                      name="logo"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: "none" }}
                      onChange={cropModalHandler}
                    />
                    <div
                      onClick={handlePenClick}
                      style={{
                        right: "-8px",
                        bottom: "112px",
                      }}
                      className="position-absolute btn opacity-50 btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    >
                      <span>
                        <i className="fa fa-pencil opacity-50"></i>
                      </span>
                    </div>
                  </div>
                  <span
                    style={{
                      right: "-7px",
                      bottom: "-10px",
                    }}
                    onClick={resetAvatarHandler}
                    className="position-absolute opacity-50 btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                  >
                    <i className="fa fa-x opacity-50"></i>
                  </span>
                </div>

                <div className="form-text">
                  Allowed file types: png, jpg, jpeg.
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                Full Name
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      name="firstName"
                      onChange={(e) => setValue("firstName", e.target.value)}
                      className="form-control form-control-lg rounded-1 border form-control-solid mb-3 mb-lg-0"
                      placeholder="First name"
                      value={firstName}
                    />
                    {errors.firstName ? (
                      <div style={{ textAlign: "end" }}>
                        <span className="error-text text-danger">
                          {errors.firstName.message}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      name="lastName"
                      onChange={(e) => setValue("lastName", e.target.value)}
                      className="form-control form-control-lg rounded-1 border form-control-solid"
                      placeholder="Last name"
                      value={lastName}
                    />
                    {errors.lastName ? (
                      <div style={{ textAlign: "end" }}>
                        <span className="error-text text-danger">
                          {errors.lastName.message}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                <span className="required">Contact Phone</span>
                <span
                  className="ms-1"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                >
                  <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                </span>{" "}
              </label>
              <div className="col-lg-8 fv-row">
                <PhoneInput 
                  onChange={(phone, data, event, formattedValue) => {
                    const counrtyCode = data?.countryCode?.toUpperCase();
                    const countryPhoneRegex = PHONE_REGEX[counrtyCode]
                    setPhoneRegex(countryPhoneRegex)
                    setValue("mobile", phone);
                    setValue("country", counrtyCode);
                  }}
                  value={mobile}
                  name="mobile"
                  className="form-control form-control-lg rounded-1 border form-control-solid"
                  placeholder="Phone number"
                  country={country?.toLowerCase()}
                  disableDropdown={true} />
                {errors.mobile ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.mobile.message}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                <span className="required">Country</span>
                <span
                  className="ms-1"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                >
                  <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                </span>{" "}
              </label>
              <div className="col-lg-8 fv-row d-flex">
              <img
                src={country ? getCountryFlag(country?.toUpperCase()) : "/flag-placeholder.png"}
                alt={country}
                className="h-20px me-2"
              />
              <p>{userCountry?.name}</p>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-Start py-lg-6 py-5 px-0 px-lg-9">
            <button
              type="reset"
              onClick={detailFormHandler}
              className="btn btn-light btn-active-light-info border rounded-1 me-2"
            >
              Cancel
            </button>
            <button
              disabled={loading}
              type="submit"
              className="btn btn-info rounded-1"
              id="kt_account_profile_details_submit"
            >
              {loading ? <Spinner /> : isEdit ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </div>

      {cropperModal ? (
        <AvatarCropper
          image={uploadedImage || "/placeholder.png"}
          toggleHandler={() => {
            setCropperModal(false);
            handleFileChange();
          }}
          cancelHandler={() => {
            setCropperModal(false);
          }}
          cropHandler={async (area = null, pixels = null) => {
            pixels
              ? await showCroppedImage(uploadedImage, pixels)
              : handleFileChange();
            setCropperModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default DetailForm;
