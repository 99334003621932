import httpMethods from "..";

const ShortLinksAPI = {
  getShortLinks: async (token) => {
    const auth_token = localStorage.getItem("auth_token");
    return httpMethods
      .get(`/short-link`, {
        headers: { Authorization: `Bearer ${auth_token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },
  getCustomLinks: async (token) => {
    return httpMethods
      .get(`/connected-custom-domain`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  searchShortLinks: async (token, query) => {
    return httpMethods
      .get(`/search-short-link`, {
        params: {
          query,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  filteredShortLinks: async (token, params) => {

    return httpMethods
      .get(`/filtered-short-link`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },
  changeStarred: async (token, id, values) => {
    return httpMethods
      .put(`/short-link-starred/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  deleteShortLink: async (token, id) => {
    return httpMethods
      .delete(`/short-link/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  bulkDeleteShortLink: async (token, payload) => {
    return httpMethods
      .post(`/bulk-delete`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  bulkStarredShortLink: async (token, payload) => {
    return httpMethods
      .post(`/bulk-starred`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  changeStatus: async (token, id, values) => {
    return httpMethods
      .put(`/short-link-status/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  addNewShortLink: async (token, values) => {
    return httpMethods
      .post(`/short-link`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  addNewUTM: async (token, values) => {
    return httpMethods
      .post(`/shortLinksUtm`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  updateShortLink: async (token, id, values) => {
    return httpMethods
      .put(`/short-link/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  importShortLinks: async (token, payload) => {
    return httpMethods
      .post(`/import-short-link`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res);
  },

  getDestinationUrl: async (payload) => {
    return httpMethods
      .post(`/destinationUrl`, payload)
      .then((res) => res);
  },

  isSlashTagValid: async (token, payload) => {
    return httpMethods
      .post(`/tagValid`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

};

export default ShortLinksAPI;
