import { Card } from "reactstrap";
import Sidebar from "../common/sidebar";

const PageWrapper = ({ children }) => {
  return (
    <div className="w-full d-flex">
      <div className="mt-10 w-[280px] me-lg-7">
        <Sidebar />
      </div>
      <div className="customDashboardSize">
        <div className="d-flex flex-column w-100 flex-root app-root">
          <div className="app-page flex-column flex-column-fluid">
            <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
              <div className="app-main flex-column flex-row-fluid">
                <Card className="card card-flush rounded-sm-0 overflow-auto">
                  {children}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
