import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import Tabs from "./tabs";
import Form from "react-bootstrap/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customLinkValidation } from "../../../utils/validations/customDomainValidation";
import DomainName from "./domainName";
import SubDomain from "./subDomain";
import { useForm } from "react-hook-form";
import CustomLinksAPI from "../../../services/http/customLinks";

const DEFAULT_VALUES = {
  spare: "Yes",
  subDomain: "",
  domain: "",
};

function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

const isUrlValid = (domain, defaultProtocol = "https") => {
  const url = /^https?:\/\//i.test(domain)
    ? domain
    : `${defaultProtocol}://${domain}`;
  return validateUrl(url);
};

const CustomLinkForm = ({
  isOpen,
  modalHandler,
  customLinksHandler,
  finalStepHandler,
}) => {
  const [tab, setTab] = useState(1);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(customLinkValidation),
    defaultValues: DEFAULT_VALUES,
  });

  const { reset, handleSubmit, watch } = form;
  const { domain } = watch();

  const messageHandler = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const resetHandler = () => {
    reset();
    setTab(1);
    modalHandler();
  };

  const submitHandler = async (data) => {
        if (tab === 2) {
      try {
        const host = data.domain;
        if (['kut.lt', 'bio.kut.lt'].includes(host)) messageHandler("You cannot add this domain as custom domain");
        else {
          setLoading(true);
          const auth_token = localStorage.getItem("auth_token");
          CustomLinksAPI.createCustomLink(auth_token, data)
            .then((response) => {
              finalStepHandler({ data: response?.data?.data, isOpen: true });
              customLinksHandler();
              resetHandler();
              setLoading(false);
            })
            .catch((error) => {
              messageHandler(
                error?.response?.data?.data?.message ||
                  error?.response?.data?.message ||
                  "Something went wrong"
              );
              setLoading(false);
            });
          }
      } catch (e) {
        console.log("Error while creating custom link : ", e);
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      className="add_new_link_body mobile-modal-view mw-700px"
      size="lg"
      isOpen={isOpen}
      toggle={resetHandler}
    >
      <ModalHeader toggle={resetHandler}>
        <h2 className="fw-bold px-lg-0 mb-0">Add Custom Domain</h2>
      </ModalHeader>
      <ModalBody className="modal-body m-lg-5">
        <Tabs tab={tab} />
        <Form onSubmit={handleSubmit(submitHandler)}>
          <TabContent activeTab={tab}>
            <TabPane tabId={1}>
              <DomainName form={form} />
            </TabPane>
            <TabPane tabId={2}>
              <SubDomain form={form} />
            </TabPane>
          </TabContent>

          <div className="d-flex justify-content-end mb-3">
            {message ? (
              <span className="text-danger fs-6 fw-bold">{message}</span>
            ) : null}
          </div>

          <div>
            {tab === 1 ? (
              <div className={`d-flex justify-content-end pt-lg-5`}>
                <button
                  disabled={!isUrlValid(domain)}
                  className="btn btn-lg btn-info rounded-1 w-lg-auto h-50px w-100"
                  onClick={() => setTab(2)}
                  type="button"
                >
                  <span>Continue</span>
                </button>
              </div>
            ) : (
              <div className={`d-flex justify-content-between pt-5`}>
                <button
                  className="btn btn-light border rounded-1 mobile-hide"
                  onClick={() => setTab(1)}
                  type="button"
                >
                  <span>
                    {" "}
                    <i className="fa-regular fa-arrow-left me-1"></i> Back
                  </span>
                </button>
                <button
                  className="btn btn-icon btn-light border rounded-1 h-50px w-50px desktop-hide"
                  onClick={() => setTab(1)}
                  type="button"
                >
                  <span>
                    {" "}
                    <i className="fa-light fa-arrow-left fs-4 me-lg-1"></i>
                  </span>
                </button>

                <button
                  disabled={loading}
                  className="btn btn-info min-w-200px ms-5 h-50px rounded-1"
                  type="submit"
                >
                  {loading ? (
                    <span>
                      Please wait....
                      <Spinner className="h-15px w-15px ms-2" />
                    </span>
                  ) : (
                    <div>
                      <span>Submit</span>
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CustomLinkForm;
