import React, { useState } from "react";
import {
  Table,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  InputGroup,
  InputGroupText,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader
} from "reactstrap";
import Sidebar from "../components/common/sidebar";
import FileUpload from "../components/common/FileUpload";
import EmptyState from "../assets/media/empty-state-link-list.png"
const apiEndpoint = "";

const Contacts = () => {
  const [isActive, setIsActive] = useState(false);
  const [searchHide, setSearchHidee] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  // Function to toggle the icon state
  const toggleIcon = () => {
    setIsActive(!isActive);
  };

  // Function to toggle the icon state
  const toggleSearch = () => {
    setSearchHidee(!searchHide);
  };

  const toggleCanvas = () => {
    setIsOpen(!isOpen);
}
const toggleForm = () => {
  setFormOpen(!formOpen);
}


 
  return (
    <div>
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush">
                  <div className="px-6 px-lg-10 py-lg-10 py-5 d-flex items-center justify-content-between w-100 align-items-center">
                    <h2 className="me-15 fs-2x">Contacts</h2>
                    <div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons">
                    <InputGroup>
    <Input placeholder="Search..." className={`headerinputsearch fw-normal form-control ${searchHide ? '' : 'd-none'}`} />
    <InputGroupText className="btn btn-icon rounded-1 bg-light-info me-2" onClick={toggleSearch}>
    <i className="fa-solid fa-magnifying-glass fs-3"></i>
    </InputGroupText>
  </InputGroup>
                      
                      <button
      type="button"
      className="btn btn-icon rounded-1 bg-light-info  px-4"
      onClick={toggleIcon}
    >
      <i className={`fs-3 fa-star ${isActive ? 'fa-solid' : 'fa-regular on-hvr-yellow text-info'}`}></i>
    </button>
    <UncontrolledDropdown className="d-inline nospce">
      <DropdownToggle
        id="dropdown-autoclose-false"
        className="btn btn-icon bg-light-info rounded-1"
        title="Tags"
      >
        <i className="fa-solid fa-tags text-info fs-3"></i>
      </DropdownToggle>
      <DropdownMenu className="py-3">
        <DropdownItem>
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
              <label className="form-check-label text-dark me-3">
                Tag 1
              </label>
              <Input
                className="form-check-input n-checked-white-hvr rounded-1 tag-chk"
                type="checkbox"
              />
            </div>
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
              <label className="form-check-label text-dark me-3">
                Tag 2
              </label>
              <Input
                className="form-check-input n-checked-white-hvr rounded-1 tag-chk"
                type="checkbox"
              />
            </div>
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
              <label className="form-check-label text-dark me-3">
                Tag 3
              </label>
              <Input
                className="form-check-input n-checked-white-hvr rounded-1 tag-chk"
                type="checkbox"
              />
            </div>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>

    <UncontrolledDropdown className="d-inline nospce">
      <DropdownToggle
        id="dropdown-autoclose-false"
        className="btn btn-icon bg-light-info rounded-1"
        title="Sort"
      >
        <i className="fa-solid fa-arrow-up-wide-short text-info fs-3"></i>
      </DropdownToggle>
      <DropdownMenu className="py-3">
        <DropdownItem>
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
              <label className="form-check-label text-dark me-3">
                A - Z
              </label>
              
            </div>
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
              <label className="form-check-label text-dark me-3">
               Z - A
              </label>
             
            </div>
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
              <label className="form-check-label text-dark me-3">
                Most Popular
              </label>
              
            </div>
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
              <label className="form-check-label text-dark me-3">
                Location
              </label>
              
            </div>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
                      
                      
                      <button type="button"  onClick={toggleCanvas} title="Bulk Import" class="btn btn-icon rounded-1 bg-light-info px-4"><i class="fa-solid fa-arrow-right-to-bracket fa-rotate-90 text-info fs-3"></i></button>
                      <button
                        type="button"
                        className="btn btn-icon rounded-1 btn-info px-4"
                        color="danger"
                        onClick={toggleForm}
                      >
                        <i className="fa-solid fa-plus text-white fs-3"></i>
                      </button>
                    </div>
                  </div>
                  <CardBody className="pt-0">
                    <Table striped>
                      <thead className="table-border">
                        <tr className="fs-6 fw-semibold gs-0 table-style text-gray-600 text-start">
                          <th className="w-10px ps-6 pe-2 py-5"><input type="checkbox" className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"/></th>
                          <th className="min-w-200px py-5">First Name</th>
                          <th className="min-w-150px py-5">Last Name</th>
                          <th className="min-w-100px py-5">Email</th>
                          <th className="min-w-100px py-5">Mobile</th>
                          <th className="min-w-100px py-5">Job</th>
                          <th className="min-w-125px py-5">Location</th>
                          <th className="min-w-50px py-5">Last Changed</th>
                          
                          <th className="min-w-50px py-5">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="fw-semibold text-gray-600">
                        <tr className="link-row border border-right-0 py-3 border-top-0 border-left-0">
                          <td className="ps-6 py-5"><input type="checkbox" className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"/></td>
                          <td className=" py-5">
                            <div className="d-flex justify-content-start align-items-center">
                              Firstname
                            </div>
                          </td>
                          <td className="text-start pe-0 py-5">Last Name</td>
                          <td className="text-start pe-0 py-5">example@gmail.com</td>
                          <td className="text-start pe-0 py-5">+91 1234567890</td>
                          <td className="text-start pe-0 py-5"> Architech </td>
                          <td className="text-start pe-0 py-5"> Mumbai, India </td>
                          <td className="text-start pe-0 py-5">Aug 25, 2023</td>
                          
                          <td className="text-end pe-0 py-5">
                          <div className="w-fit-content d-flex align-items-center">
            <button
              type="button"
              className="btn px-0 py-0"
             
          
            >
              <i
                className="fa-star fs-2 cursor-pointer fa-regular on-hvr-yellow text-warning"
               
              ></i>
            </button>
           
            <button
              type="button"
              className="btn px-0 py-0"
             
          
            >
              <i
                className="fa-edit fs-2 cursor-pointer fa-regular  ms-5"
               
              ></i>
            </button>
          
            <button
              type="button"
              className="btn px-0 py-0">
                <i
                style={{ marginTop: "-4px" }}
                title="Delete"
                className="fa-regular fa-trash-can fs-2 ms-5 text-danger cursor-pointer"
              />
            </button>
          </div>
                          </td>
                        </tr>
                        <tr className="d-none">
                          <td colspan="9" className="py-5 bg-white">
                          <div className="d-flex flex-column justify-content-center align-items-center mt-15">
											<img className="opacity-50 img-fluid" src={EmptyState} alt=""/>
											<div className="text-center mt-lg-10">
											<h2 className="fs-2x pt-5 pb-5 fw-bold">No Contacts to display.</h2>
											<p className="w-75 mx-auto fw-semibold fs-5 text-gray-500 mb-8">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odit, deserunt. Lorem ipsum dolor sit amet.</p>
											<button type="button" className="btn btn-info rounded-1"  >Add New Contact</button>
											</div>	
											</div>

                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas isOpen={formOpen} toggle={toggleForm}  className="custom-canvas-width" direction='end'>
        <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={toggleForm}>
        <h3 className='font-weight-bold'>Create Contact </h3>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2">First Name</Label>
                <Input type="text" className="form-control rounded-1 h-50px" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2">Last Name</Label>
                <Input type="text" className="form-control rounded-1 h-50px" />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2">Email Address</Label>
                <Input type="text" className="form-control rounded-1 h-50px" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2">Mobile Number</Label>
                <Input type="text" className="form-control rounded-1 h-50px" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2">Job</Label>
                <Input type="text" className="form-control rounded-1 h-50px" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2">Location</Label>
                <Input type="text" className="form-control rounded-1 h-50px" />
              </FormGroup>
            </Col>
            
            <Col md={6}>
              <FormGroup>
                <Label className="fs-5 fw-bold mb-2">Tags</Label>
                <div className="custom-dropdown form-control p-0">
                  <UncontrolledDropdown
                    className="dropdown-header fs-5 min-w-300px my-auto btn-flex btn-center"
                    direction="down"
                  >
                    <DropdownToggle caret color="transparent">
                      kut.lt
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Header</DropdownItem>
                      <DropdownItem disabled>Action</DropdownItem>
                      <DropdownItem>Another Action</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>Another Action</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="ms-auto text-end btn-info">
              <Button
                color="primary"
                className="btn btn-info h-50px mt-10 w-100 d-block rounded-1 text-white"
              >
                {" "}
                Create Contact
              </Button>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas isOpen={isOpen} toggle={toggleCanvas} className="custom-canvas-width" direction='end'>
                    <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={toggleCanvas}>
                        <h3 className='font-weight-bold'> Upload Products</h3>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <FileUpload apiEndpoint={apiEndpoint}/>
                    </OffcanvasBody>
                </Offcanvas>
    </div>
    </div>
  );
};

export default Contacts;
